<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import { campaignMethods, masterMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

export default {
    page: {
        title: '削除してもよろしいですか。',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout
    },
    data() {
        return {
            isLoading: false,
            templateDetail: {},
            documentPreviewData: {}
        };
    },
    watch: {},
    mounted() {
        if (this.$route.query.id) {
            this.getTemplateDetail();
        }
    },
    methods: {
        ...masterMethods,
        ...campaignMethods,
        getTemplateDetail() {
            let template = {
                id: this.$route.query.id
            };
            this.isLoading = true;
            this.templateGetDetail(template).then((data) => {
                if (data.code == 200 && data.data) {
                    this.templateDetail = data.data;
                    if (this.$route.query.campaign_id) {
                        this.getListMasterTemplate();
                    }
                }
                this.isLoading = false;
            });
        },
        getListMasterTemplate() {
            let campaign = {
                campaign_id: this.$route.query.campaign_id
            };
            this.isLoading = true;
            this.documentPreview(campaign).then((data) => {
                if (data.code == 200) {
                    this.documentPreviewData = data.data.context_data;
                    let data1 = JSON.parse(JSON.stringify(this.templateDetail.content));
                    data1 = data1.replace('《キャンペーン名》', this.documentPreviewData.name);
                    data1 = data1.replace('《キャンペーン開始》', this.documentPreviewData.campaign_start_date);
                    data1 = data1.replace('《キャンペーン事務局TEL》', this.documentPreviewData.tel);
                    data1 = data1.replace('《キャンペーン事務局Mailアドレス》', this.documentPreviewData.email);
                    data1 = data1.replace('《事務局開始》', this.documentPreviewData.secretariat_start_date);
                    data1 = data1.replace('《事務局終了》', this.documentPreviewData.secretariat_end_date);
                    data1 = data1.replace(
                        '《年末年始休み》',
                        `(${this.documentPreviewData.year_end_schedule_from} ~ ${this.documentPreviewData.year_end_schedule_to})`
                    );
                    this.templateDetail.content = JSON.parse(JSON.stringify(data1));
                }
                this.isLoading = false;
            });
        },
        goEdit(path) {
            this.$router.push({ path: path });
        },

        register() {
            let objectData = {
                campaign_id: parseInt(this.$route.query.campaign_id),
                documents: [
                    {
                        source: this.$route.query.id,
                        data: {}
                    }
                ]
            };
            this.isLoading = true;
            this.postDocuments(objectData).then((data) => {
                if (data.code == 200) {
                    this.$router.push({ path: '/documents' }).then(() => {
                        showMessage(data.code, this.$bvToast, '登録されました。');
                    });
                } else {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                }

                this.isLoading = false;
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center" v-if="!isLoading">
            <div class="col-lg-12">
                <div class="card form--sroll1 mb-0">
                    <div class="card-body">
                        <div class="form-group row">
                            <label class="col-sm-3">内容</label>
                            <div class="col-sm-8">
                                <div v-html="templateDetail.content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="custom__positon--fixed form__detail--common">
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button type="button" class="btn btn-light" @click="goEdit('/documents')">キャンセル</button>
                        <button type="button" @click="register()" class="btn btn-primary ml-3">出力</button>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
.item2 {
    padding-top: 5px;
}
.item2:last-child {
    border: 0 !important;
}
.item2:hover {
    background: rgba(239, 239, 239, 0.28);
}
</style>
